@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("/src/assets/font/Basier-Square-regular-webfont/stylesheet.css");

.light {
  --dark-primery: 223, 144, 27 ;/*#DF901B */
  --transaction-bg: 157, 138, 87, 1; /* #9D8A57 */
  --button-hover-bg: 223, 189, 104, 1; /* #DFBD68 icon-btn-hover */
  --button-bg: 239, 198, 96, 1; /* #EFC660  icon-btn*/
  --button-text: 95, 77, 33, 1; /* #5F4D21 primary-item*/
  --input-bg: 255, 255, 255, 1; /* #FFFFFF  dropdown-popup-bg */
  --active-state-bg: 215, 211, 199, 1; /* #D7D3C7 */
  --body-bg: 244, 244, 244, 1; /* #F4F4F4 */
  --body-text: 15, 15, 15, 1; /* #0F0F0F */
  --highlighter-green: 22, 126, 66, 1; /* #167E42 */
  --divider-border: 229, 229, 231, 1; /* #E5E5E7 */
  --popup-bg: 255, 255, 255, 1; /* #FFFFFF */
  --active-dropdown: 233, 232, 227, 1; /* #E9E8E3 */
  --dropdown-popup-bg: 255, 255, 255, 1; /* #FFFFFF */
  --highlighter-red: 236, 12, 0; /* #EC0C00 */
  --hover-state-bg: 223, 219, 211, 1; /* #DFDBD3 */
  --icon-btn: 239, 198, 96, 1; /* #EFC660 */
  --icon-btn-hover: 223, 189, 104, 1; /* #DFBD68 */
  --input-active-bg: 220, 218, 208, 1; /* #DCDAD0 */
  --option-list-hover: 247, 246, 244, 1; /* #F7F6F4 */
  --option-list-selected: 254, 252, 247, 1; /* #FEFCF7 */
  --paragraph-text: 116, 115, 111, 1; /* #74736F */
  --primary-item: 95, 77, 33, 1; /* #5F4D21  primary-item*/
  --primary-Icon-bg: 250, 238, 207, 1; /* #FAEECF */
  --pimary-icon-hover: 249, 235, 199, 1; /* #F9EBC7 */
  --secondary-icon-bg: 233, 232, 227; /* #E9E8E3 */
  --tabs-gradient: 255, 255, 255, 1; /* #FFFFFF */
  --skeleton-start: 249, 249, 246, 1; /* #F9F9F6 */
  --skeleton-end: 233, 232, 227, 1; /* #E9E8E3 */
  --primarycard-gradient: linear-gradient(
    98deg,
    #f7f7f7 3.96%,
    #fff 49.22%,
    #f7f7f7 95.85%
  );
  --side-gradient: linear-gradient(
    0deg,
    #3f3009 20.71%,
    #3f3009 24.74%,
    #3f300944 100%
  );
}

.dark {
  --dark-primery: 239, 198, 96, 1;/*#EFC660 */
  --option-list-selected: 65, 61, 51, 1; /* #413D33 */
  --transaction-bg: 157, 138, 87, 1; /* #9D8A57 */
  --button-hover-bg: 223, 189, 104, 1; /* #DFBD68 icon-btn-hover */
  --button-bg: 239, 198, 96, 1; /* #EFC660 icon-btn */
  --button-text: 95, 77, 33, 1; /* #5F4D21 primary-item*/
  --input-bg: 32, 32, 30, 1; /* #20201E dropdown-popup-bg */
  --active-state-bg: 71, 61, 35, 1; /* #473D23 */
  --body-bg: 15, 15, 15, 1; /* #0F0F0F */
  --body-text: 255, 255, 255, 1; /* #FFFFFF */
  --highlighter-green: 21, 191, 93, 1; /* #15BF5D */
  --divider-border: 58, 58, 57, 1; /* #3A3A39 */
  --highlighter-red: 254, 67, 57; /* #FE4339 */
  --hover-state-bg: 49, 43, 27, 1; /* #312B1B */
  --icon-btn: 239, 198, 96, 1; /* #EFC660 */
  --icon-btn-hover: 239, 198, 96, 1; /* #EFC660 */
  --paragraph-text: 174, 172, 164, 1; /* #AEACA4 */
  --popup-bg: 32, 32, 30, 1; /* #20201E */
  --active-dropdown: 63, 63, 59, 1; /* #3F3F3B */
  --primary-item: 239, 198, 96, 1; /* #EFC660 */
  --primary-Icon-bg: 94, 82, 50, 1; /* #5E5232 */
  --pimary-icon-hover: 71, 61, 35, 1; /* #473D23 */
  --secondary-icon-bg: 38, 34, 24; /* #262218 */
  --dropdown-popup-bg: 32, 32, 30, 1; /* #20201E */
  --option-list-hover: 42, 39, 33, 1; /* #2A2721 */
  --primary-shade-color: 239, 198, 96, 0.3; /* ##EFC6604D */
  --skeleton-start: 63, 63, 59, 1; /* #3F3F3B */
  --skeleton-end: 38, 34, 24, 1; /* #262218 */
  --tabs-gradient: linear-gradient(
    95deg,
    #1b1b19 17.71%,
    #292928 51.74%,
    #1b1b19 89.56%
  );
  --primarycard-gradient: linear-gradient(
    98deg,
    #1a1a18 3.96%,
    #20201e 52.42%,
    #1a1a18 95.85%
  );
  --side-gradient: linear-gradient(
    0deg,
    #3f3009 20.71%,
    #3f3009 24.74%,
    #3f300944 100%
  );
}

/* Keyframes for the skeleton animation */
@keyframes changeColor-light {
  0% {
    background: linear-gradient(
      90deg,
      rgba(249, 249, 246, 1) 0%,
      rgba(233, 232, 227, 1) 46%
    );
  }
  40% {
    background: linear-gradient(
      90deg,
      rgba(249, 249, 246, 1) 20%,
      rgba(233, 232, 227, 1) 66%
    );
  }
  60% {
    background: linear-gradient(
      90deg,
      rgba(249, 249, 246, 1) 20%,
      rgba(233, 232, 227, 1) 66%
    );
  }
  80% {
    background: linear-gradient(
      90deg,
      rgba(233, 232, 227, 1) 40%,
      rgba(249, 249, 246, 1) 76%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      rgba(249, 249, 246, 1) 80%,
      rgba(233, 232, 227, 1) 96%
    );
  }
}
@keyframes changeColor-dark {
  0% {
    background: linear-gradient(
      90deg,
      rgba(63, 63, 59, 1) 0%,
      rgba(38, 34, 24, 1) 46%
    );
  }
  40% {
    background: linear-gradient(
      90deg,
      rgba(63, 63, 59, 1) 20%,
      rgba(38, 34, 24, 1) 66%
    );
  }
  60% {
    background: linear-gradient(
      90deg,
      rgba(63, 63, 59, 1) 20%,
      rgba(38, 34, 24, 1) 66%
    );
  }
  80% {
    background: linear-gradient(
      90deg,
      rgba(63, 63, 59, 1) 40%,
      rgba(38, 34, 24, 1) 76%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      rgba(63, 63, 59, 1) 80%,
      rgba(38, 34, 24, 1) 96%
    );
  }
}

/* Apply the animation to the element */
.light .skeleton-animation {
  animation: changeColor-light 2s ease infinite; /* Change the duration (3s) as needed */
}
.dark .skeleton-animation {
  animation: changeColor-dark 2s ease infinite; /* Change the duration (3s) as needed */
}
/* Hide scrollbar for all browsers 
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;  
}
::-webkit-scrollbar {
  display: none;
}

*/


.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}


.custom-scrollbar {
  scrollbar-color: transparent, transparent;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #d9d9d9;
}


/* For IE, Edge, and Firefox */
html,
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  overflow: scroll; /* Ensure scrolling is enabled */
}

/* Custom CSS to hide arrows */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrows {
  -moz-appearance: textfield;
}

/* Hide scrollbar for all browsers */
.hide-scrollbar {
  /* Chrome, Safari, and Opera */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
  /* Hide scrollbar for all other browsers */
  /* overflow: hidden;  */
}

.light .autofill-input {
  transition: background-color 5000s ease-in-out 0s;
}

.light .autofill-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #f0f0f0 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.light .autofill-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #000 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.light .autofill-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #000 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.dark .autofill-input {
  transition: background-color 5000s ease-in-out 0s;
}

.dark .autofill-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #f0f0f0 to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

.dark .autofill-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #ffff to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

.dark .autofill-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #ffff to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

/* Others Accounts Border Animations */
@keyframes borderAnimation {
  0% {
    border-top-color: #efc660;
    border-right-color: #efc660;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  25% {
    border-top-color: transparent;
    border-right-color: #efc660;
    border-bottom-color: #efc660;
    border-left-color: transparent;
  }
  50% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #efc660;
    border-left-color: #efc660;
  }
  75% {
    border-top-color: #efc660;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #efc660;
  }
  100% {
    border-top-color: #efc660;
    border-right-color: #efc660;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.borderAnimation:hover {
  animation: borderAnimation 2s linear infinite;
  border-style: solid;
  border-width: 1px;
  border-color: transparent; /* Initial color */
}

.slick-dots li button:before {
  @apply text-body-text !important;
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
  @apply text-primary-item !important;
}

.slick-slide > div {
  margin: 0 10px;
}

@layer utilities {
   @keyframes breathing {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
  } 

   .animate-breathing {
    animation: breathing 2s infinite;
  } 

  @keyframes progress {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .animate-progress {
    animation: progress 1.5s linear infinite;
  }
}